import React from "react";

export default function Intro() {
    return <>
        <h1 className={'text-white text-center text-5xl md:text-6xl font-bold mt-10 text-pretty'}>
            Nexus Exit Weihnachtsrätsel
        </h1>
        <p className={'text-white text-center font-bold md:text-xl mt-8 text-pretty'}>
            Könnt ihr unsere Uhren finden und Weihnachten retten?
            <br/>
            Schaut euch die Videos an!
            <br/>
            <br/>
            <span className={'bg-red-500/80 p-2 rounded-2xl'}>Hohe Gewinnchancen bei jedem einzelnen Rätsel.</span>
        </p>
    </>
}