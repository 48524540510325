import React from "react";

export default function SoFunktionierts() {
    return (

        <div className={'mt-8 flex flex-col justify-center items-center'}>
            <div className="m-8 divide-y divide-gray-200/50 overflow-hidden rounded-lg bg-white/65 shadow max-w-max">
                <div className="px-4 py-4 sm:px-6">
                    <p className={'font-bold text-2xl'}>So funktioniert's:</p>
                </div>
                <div className="px-4 pb-5 pt-2 sm:p-6">
                    <ol className={'ml-4'} type={'A'} style={{listStyle: 'initial'}}>
                        <li>Jeden Adventssonntag posten wir ein neues Rätsel als Video auf Instagram.</li>
                        <li className={'mt-2'}>Findet die versteckte Uhr aus dem Video in Gelnhausen.</li>
                        <li className={'mt-2'}>Macht ein Foto von der Uhr, packt es in eure Instagram-Story und verlinkt <a className={'underline'} href={'https://www.instagram.com/nexusexit'}>@nexusexit</a>.</li>
                        <li className={'mt-2'}>Ihr habt Zeit bis zum 20.12. um 24:00! Die Auflösung und Gewinnerverkündung gibt es am Tag darauf.</li>
                    </ol>
                </div>
            </div>
        </div>
    )
}
