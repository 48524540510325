import React from "react";

export function YouTubeVideo({src, title, uhr, comingSoon = false}: { src: string, title: string, uhr: string, comingSoon?: boolean }) {
    return (
        <div className="m-4 divide-y divide-gray-200/50 overflow-hidden rounded-lg bg-white/65 shadow max-w-max">
            <div className="px-4 py-4 sm:px-6">
                <p className={'text-center text-xl font-bold'}>{title}{!comingSoon && ': ' + uhr}</p>
            </div>
            <div className="px-4 p-5 sm:p-6">
                {comingSoon && <p className={'text-center font-bold'}>COMING SOON ...</p>}
                {!comingSoon && <iframe width="max-w-max"
                                        height="600"
                                        src={src}
                                        title={title}
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin">
                </iframe>}
            </div>
        </div>
    )
}

export function Videos() {
    return <>
        <div className={'mt-8 flex flex-wrap justify-center items-center'}>
            <YouTubeVideo src={'https://www.youtube.com/embed/6AkHsh9erPg'} title={'1. Rätsel'} uhr={'Taschenuhr'}/>
            <YouTubeVideo src={'https://www.youtube.com/embed/AL1zhgm61Qg'} title={'2. Rätsel'} uhr={'Armbanduhr'}/>
            <YouTubeVideo src={'https://www.youtube.com/embed/gQdoyAT1fjk'} title={'3. Rätsel'} uhr={'Digitaluhr'}/>
        </div>
    </>
}