import React from 'react';
import {Videos} from "./YouTubeVideo";
import SoFunktionierts from "./SoFunktionierts";
import Intro from "./Intro";

export default function App() {
    return <>
        <Intro/>
        <Videos/>
        <SoFunktionierts/>
    </>;
}

